import * as React from 'react';
import { useEffect } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Container } from '@mui/material';
import { MainListItems, BottomListItems } from '../components/Dashboard/ListItems'; // Reuse these
import { Outlet } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import AccountDropdown from './Dashboard/AccountDropDown';
import NotificationBadge from './Notifications/NotificationBadge';

// Constants
const drawerWidth = 240;

// AppBar interface and styling
interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

// Drawer (Sidebar) styling
const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

// Dark theme
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const Layout: React.FC = () => {
  const [open, setOpen] = React.useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  const toggleDrawer = () => {
    setOpen(!open);
  };

  
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/signin'); // Redirect to login if no token is present
    }
  }, [navigate]);

  const searchParams = new URLSearchParams(location.search);
  const tab = searchParams.get('tab');

  const getTitle = () => {
    if (location.pathname === '/safeguarding') {
      return tab === '1' ? 'Safeguarding' : 'Treasury';
    }
    switch (location.pathname) {
      case '/dashboard':
        return 'Dashboard';
      case '/accounts':
        return 'Accounts';
      case '/deposit':
        return 'Deposit as a Service';
      case '/documentation':
        return 'API Documentation';
      case '/settings':
        return 'Settings';
      case '/wallet':
        return 'Wallet';
      default:
        return 'Dashboard';
    }
  }
  

  return (
    <ThemeProvider theme={darkTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
             { getTitle()}
            </Typography>

            {/* Account Dropdown */}
            <AccountDropdown />
            <NotificationBadge />
  
          </Toolbar>
        </AppBar>

        <Drawer variant="permanent" open={open}>
        <Toolbar
  sx={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center', // Center everything in the toolbar
    px: [1],
    position: 'relative', // Set position for absolute placement of the arrow
  }}
>
  {/* Logo in the center */}
  <Box
    component="img"
    src={process.env.PUBLIC_URL + '/logo.png'}
    alt="Logo"
    sx={{
      height: 50, // Adjust the height
      width: 'auto', // Maintain aspect ratio
    }}
  />

  {/* Arrow on the right */}
  <IconButton
    onClick={toggleDrawer}
    sx={{
      position: 'absolute', // Absolute positioning for the arrow
      right: 0, // Move to the right side of the toolbar
    }}
  >
    <ChevronLeftIcon />
  </IconButton>
</Toolbar>
          <Divider />

          {/* Sidebar List Items */}
          <List component="nav" sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ flexGrow: 1 }}>
              <MainListItems />
            </Box>
            <BottomListItems />
          </List>
        </Drawer>

        {/* Main Content - this is where different pages will load */}
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
            <Outlet /> {/* This allows different pages to be loaded here */}
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Layout;